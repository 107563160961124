/* ------------------------- Components ® Code by Dennis -------------------------------------------------- */

/* ------------------------- Default Header -------------------------------------------------- */

.default-header {
   padding-top: calc(var(--vh) * 25);
}

.default-header .row-title {
   justify-content: center;
   text-align: center;
}

.default-header .row-title .col {
   max-width: calc(var(--title-size) * 11);
}

/* ------------------------- Split Words -------------------------------------------------- */

.split-words {
   position: relative;
   transform: translateY(0) translateZ(0) rotate(0.001deg);
}

.split-words .single-word {
   position: relative;
   overflow: hidden;
   transform: translateY(10%) rotate(0.001deg);
   margin: -0.17em 0 -0.08em 0;
   padding: 0.08em 0;
}

.split-words .single-word .single-word-inner {
   position: relative;
   transform: translateY(6%) rotate(0.001deg);
   transform-origin: left top;
}

/* ------------------------- Custom Scrollbar -------------------------------------------------- */

[data-scrollbar] {
   width: 5px;
   position: fixed;
   top: 5px;
   right: 5px;
   bottom: 5px;
   z-index: 999;
   display: none;
   opacity: 0;
   transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
   opacity: 0;
   justify-content: center;
}

[data-scrollbar] [data-scrollbar-thumb] {
   min-height: 2em;
   height: 4em;
   background-color: #889c9a;
   cursor: grab;
   border-radius: 30px;
   width: 100%;
}

.lenis-smooth.lenis-scrolling [data-scrollbar] {
   opacity: 1;
}

[data-scrollbar]:hover,
[data-scrollbar-drag="true"]  {
   opacity: 1;
   width: 9px;
}

@media screen and (min-width: 1024px) {
   .lenis-smooth [data-scrollbar] {
      display: flex;
   }
}

/* ------------------------- Single - Vimeo Background -------------------------------------------------- */

.single-vimeo-background {
   --aspect-ratio-w: 16;
   --aspect-ratio-h: 9;
   --parent-height: 100;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: calc(var(--parent-height) * 1vh);
   overflow: hidden;
   background: var(--color-dark);
   transform: translateZ(0);
   isolation: isolate;
   pointer-events: none;
}

.single-vimeo-background iframe {
   width: 100vw;
   height: calc(var(--aspect-ratio-h)/var(--aspect-ratio-w)*(var(--parent-height) * 1vw));
   min-height: calc(var(--parent-height) * 1vh);
   min-width: calc(var(--aspect-ratio-w)/var(--aspect-ratio-h)*(var(--parent-height) * 1vh));
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.single-vimeo-background .overlay-dark {
   background-color: var(--color-dark);
}

/* Placeholder */

.single-vimeo-background .vimeo-overlay-placeholder {
   background: var(--color-dark);
   transition: opacity 0.3s ease-in-out;
   opacity: 1;
}

.single-vimeo-background[data-vimeo-status-activated="true"][data-vimeo-status-loaded="true"] .vimeo-overlay-placeholder {
   opacity: 0;
}

@media screen and (max-width: 1024px) {
   .single-vimeo-background {
      --parent-height: 100;
   }
}

/* ------------------------- Single - Vimeo Player -------------------------------------------------- */

.single-vimeo-player {
  --aspect-ratio: 56.24%;
	position: relative;
	width: 100%;
	padding-bottom: var(--aspect-ratio);
	overflow: hidden;
   background: var(--color-gray);
   border-radius: var(--border-radius-medium);
   transform: translateZ(0);
   isolation: isolate;
}

.single-vimeo-player iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.single-vimeo-player[data-vimeo-player-target] {
   cursor: pointer;
}

.scroll-to-target {
   width: 100vw;
   height: calc(100 * var(--vh));
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   pointer-events: none;
}

/* Placeholder */

.single-vimeo-player .vimeo-overlay-placeholder {
	transition: opacity 0.3s ease-in-out;
	opacity: 1;
}

.single-vimeo-player[data-vimeo-status-activated="true"][data-vimeo-status-loaded="true"] .vimeo-overlay-placeholder {
	opacity: 0;
}

/* Overlay Dark */

.single-vimeo-player .vimeo-overlay-dark {
	background: var(--color-black);
	transition: opacity 0.3s ease-in-out;
   opacity: 0;
   z-index: 2;
   pointer-events: none;
}

.single-vimeo-player[data-vimeo-status-activated="true"] .vimeo-overlay-dark {
	opacity: 0.5;
}

.single-vimeo-player[data-vimeo-status-activated="true"][data-vimeo-status-loaded="true"] .vimeo-overlay-dark {
	opacity: 0;
}

@media (hover: hover) { 
   .single-vimeo-player[data-vimeo-status-hover="true"]:hover .vimeo-overlay-dark {
      opacity: 0.3 !important;
   }
}

/* Interface */

.single-vimeo-player .vimeo-overlay-interface {
   pointer-events: none;
   z-index: 4;
   opacity: 0;
   transition: opacity 0.3s ease-in-out;
   display: flex;
   align-items: flex-end;
   padding: calc(var(--gap) * 1.6) calc(var(--gap) * 2);
   gap: calc(var(--gap) * 1);
}

.single-vimeo-player[data-vimeo-status-activated="true"][data-vimeo-status-play="false"] .vimeo-overlay-interface,
.single-vimeo-player[data-vimeo-status-activated="true"][data-vimeo-status-hover="true"]:hover .vimeo-overlay-interface {
   opacity: 1;
}

@media screen and (max-width: 1024px) { 
   .single-vimeo-player[data-vimeo-status-activated="true"][data-vimeo-status-play="true"] .vimeo-overlay-interface {
      opacity: 0 !important;
   }
}

.single-vimeo-player .vimeo-overlay-interface * {
   pointer-events: all;
}

.single-vimeo-player .vimeo-mute {
   height: 1.5em;
   width: 1.5em;
   position: relative;
   cursor: pointer;
   flex-shrink: 0;
}

.single-vimeo-player .vimeo-mute svg {
   width: 100%;
   display: block;
}

.single-vimeo-player .vimeo-mute svg path {
   fill: var(--color-light);
}

.single-vimeo-player .vimeo-mute svg:nth-child(2) {
   display: none;
}

.single-vimeo-player[data-vimeo-status-muted="true"] .vimeo-mute svg:nth-child(1) {
   display: none;
}

.single-vimeo-player[data-vimeo-status-muted="true"] .vimeo-mute svg:nth-child(2) {
   display: block;
}

.single-vimeo-player .vimeo-duration {
   height: 1.5em;
   width: 2em;
   display: flex;
   align-items: baseline;
   flex-shrink: 0;
}

.single-vimeo-player .vimeo-duration span {
   color: var(--color-light);
   font-weight: 400;
   white-space: nowrap;
}

/* Timeline */

.single-vimeo-player .vimeo-timeline {
   height: 1.5em;
   width: 100%;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   --timeline-rounded-corners: 1.5em;
   --progress-value: var(--color-primary);
   --progress-background: rgba(var(--color-light-rgb), 0.5);
   --progress-height: 2px;
   --dot-height: 0.6em;
   --dot-color: var(--color-secondary);
}

.single-vimeo-player .vimeo-timeline > input {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   position: absolute;
   display: block;
   background: transparent;
   position: relative;
   z-index: 2;
   width: 100%;
   height: 100%;
   cursor: pointer;
}

.single-vimeo-player .vimeo-timeline > progress {
   width: 100%;
   position: absolute;
   left: 0;
   height: var(--progress-height);
   margin: 0;
   padding: 0;
   vertical-align: top;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   border-radius: var(--timeline-rounded-corners);
   overflow: hidden;
   z-index: 0;
   background: transparent;
   border: none;
   color: var(--progress-value);
   cursor: pointer;
}

/* Progress */

.single-vimeo-player progress::-webkit-progress-bar {
  border-radius: var(--timeline-rounded-corners);
  background-color: var(--progress-background);
  box-shadow: 0;
}

.single-vimeo-player progress::-webkit-progress-value {
  background: var(--progress-value);
}

.single-vimeo-player progress::-moz-progress-bar {
  border-radius: var(--timeline-rounded-corners);
  background: var(--progress-value);
  box-shadow: 0;
}

.single-vimeo-player progress::-ms-fill {
  border-radius: var(--timeline-rounded-corners);
}

/* Range */

.single-vimeo-player [type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
}
 
.single-vimeo-player [type="range"]:focus {
   outline: none;
}
 
.single-vimeo-player [type="range"]::-ms-track {
   width: 100%;
   cursor: pointer;
   background: transparent;
   border-color: transparent;
   color: transparent;
}

.single-vimeo-player [type="range"].time::-webkit-slider-runnable-track{
  width: 100%;
  height: var(--progress-height);
  cursor: pointer;
  background: var(--progress-background);
  border-radius: var(--timeline-rounded-corners)
}

.single-vimeo-player [type="range"]::-webkit-slider-thumb {
  box-shadow: 0;
  height: var(--dot-height);
  width: var(--dot-height);
  border-radius: var(--timeline-rounded-corners);
  background: var(--dot-color);
  cursor: pointer;
  -webkit-appearance: none;
}

.single-vimeo-player [type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

.single-vimeo-player [type="range"]::-moz-range-track {
  width: 100%;
  height: var(--progress-height);
  cursor: pointer;
  background: var(--progress-background);
  border-radius: 0;
  border: 0;
  border-radius: var(--timeline-rounded-corners);
  overflow: hidden;
}

.single-vimeo-player [type="range"]::-moz-range-thumb {
  box-shadow: 0;
  border: 0;
  height: var(--dot-height);
  width: var(--dot-height);
  border-radius: var(--timeline-rounded-corners);
  background: var(--dot-color);
  cursor: pointer;
  box-shadow: 0;
}

/* Icon (Play + Pause) */

.single-vimeo-player .icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: calc(var(--title-size) * 0.8);
   transform: scale(1) rotate(0.001deg);
   transition: opacity 0.3s ease-in-out;
   background: var(--color-light);
   border-radius: 50%;
   aspect-ratio: 1/1;
}

.single-vimeo-player .icon svg {
   width: 50%;
}

/* Play */

.single-vimeo-player .vimeo-overlay-play {
	z-index: 2;
	display: flex;
   align-items: center;
	justify-content: center;
   cursor: pointer;
   opacity: 1;
}

.single-vimeo-player[data-vimeo-status-play="true"] .vimeo-overlay-play {
   opacity: 0;
}

.single-vimeo-player .vimeo-overlay-play .icon svg path {
   fill: var(--color-dark);
}

/* Pause */

.single-vimeo-player .vimeo-overlay-pause {
	z-index: 3;
	display: none;
   align-items: center;
	justify-content: center;
   cursor: pointer;
}

.single-vimeo-player[data-vimeo-status-play="true"] .vimeo-overlay-pause {
   display: flex;
}

.single-vimeo-player .vimeo-overlay-pause .icon {
   opacity: 0;
}

.single-vimeo-player .vimeo-overlay-pause .icon svg path {
   stroke: var(--color-dark);
}

.single-vimeo-player[data-vimeo-status-activated="true"][data-vimeo-status-play="false"] .vimeo-overlay-pause  .icon,
.single-vimeo-player[data-vimeo-status-activated="true"][data-vimeo-status-hover="true"]:hover .vimeo-overlay-pause  .icon {
   opacity: 1;
}

@media screen and (max-width: 1024px) { 
   .single-vimeo-player[data-vimeo-status-activated="true"][data-vimeo-status-play="true"] .vimeo-overlay-pause  .icon {
      opacity: 0 !important;
   }
}

/*  Loading */

.single-vimeo-player .vimeo-overlay-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;
	transition: all 0.3s ease-in-out 0.5s;
   opacity: 0;
   pointer-events: none;
   background-color: rgba(var(--color-black-rgb), 0.5);
}

.single-vimeo-player[data-vimeo-status-play="true"] .vimeo-overlay-loading {
	opacity: 1;
}

.single-vimeo-player[data-vimeo-status-play="true"][data-vimeo-status-loaded="true"] .vimeo-overlay-loading {
	opacity: 0;
}

.single-vimeo-player .vimeo-overlay-loading svg {
	width: 8em;
}

.single-vimeo-player .vimeo-overlay-loading svg path {
	fill: var(--color-white);
}

/* ------------------------- Marquee -------------------------------------------------- */

.marquee-group {
   position: relative;
}

.marquee {
   position: relative;
}

.marquee .marquee-scroll {
   position: relative;
   display: flex;
   width: 140%;
   margin-left: -20%;
}

.marquee .marquee-content {
   position: relative;
   display: flex;
}

.marquee .marquee-item {
   width: 15vw;
}

/* ------------------------- Custom Cursor -------------------------------------------------- */

.custom-cursor {
   position: fixed;
   z-index: 100;
   pointer-events: none;
   transform: translate(-50%, -50%) rotate(0.001deg);
   display: none;
   opacity: 0;
   visibility: hidden;
   display: flex;
   pointer-events: none;
}

/*  Cursor Bubble  */

.custom-cursor .cursor-bubble {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%) scale(0) rotate(-12deg);
   display: flex;
   align-items: center;
   justify-content: center;
   width: 4.5em;
   transition: transform var(--animation-cursor) 0.1s;
   overflow: hidden;
   border-radius: 50%;
   font-size: 1.1em;
}

.custom-cursor[data-cursor-bubble="active"] .cursor-bubble {
   transform: translate(-50%, -70%) scale(1) rotate(0.001deg);
   transition: transform var(--animation-cursor) 0s;
}

.custom-cursor .cursor-bubble .cursor-before {
   position: relative;
   width: 100%;
   padding-top: 100%;
}

.custom-cursor .cursor-bubble .cursor-background {
   position: absolute;
   left: 50%;
   width: 100%;
   height: 100%;
   top: 50%;
   transform: translate(-50%, -50%) rotate(0.001deg);
   background: var(--color-white);
   border-radius: 50%;
   transition: transform var(--animation-cursor) 0.1s;
}


.custom-cursor[data-cursor-bubble="active"] .cursor-bubble .cursor-background {
   transform: translate(-50%, -50%) rotate(0.001deg);
   transition: transform var(--animation-cursor) 0s;
}

.custom-cursor .cursor-bubble .cursor-text,
.custom-cursor .cursor-bubble .cursor-text-drag {
   font-family: 'Formula Condensed', sans-serif;
   white-space: nowrap;
   position: absolute;
   top: 52%;
   left: 50%;
   transform: translate(-50%, -50%) scale(0) rotate(0.001deg);
   color: var(--color-dark);
   font-size: 1.1em;
   text-transform: uppercase;
   font-weight: 600;
   line-height: 0.8;
   padding: 0.5em;
   opacity: 0;
   transition: all var(--animation-cursor) 0.2s;
}

.custom-cursor[data-cursor-bubble="active"] .cursor-bubble .cursor-text {
   transform: translate(-50%, -50%) scale(1) rotate(0.001deg);
   opacity: 1;
   transition: all var(--animation-cursor) 0s;
}

.custom-cursor[data-cursor-bubble="active"] .cursor-bubble .cursor-text-drag {
   transform: translate(-50%, -50%) scale(0) rotate(0.001deg);
   opacity: 0;
   transition: all var(--animation-cursor) 0s;
}

.custom-cursor[data-cursor-status-move="active"][data-cursor-bubble="active"] .cursor-bubble .cursor-text,
.custom-cursor[data-cursor-status-drag="active"][data-cursor-bubble="active"] .cursor-bubble .cursor-text,
.custom-cursor[data-cursor-status-onlydrag="active"][data-cursor-bubble="active"] .cursor-bubble .cursor-text  {
   transform: translate(-50%, -50%) scale(0) rotate(0.001deg);
   opacity: 0;
   transition: all var(--animation-cursor) 0s;
}

.custom-cursor[data-cursor-status-move="active"][data-cursor-bubble="active"] .cursor-bubble .cursor-text-drag,
.custom-cursor[data-cursor-status-drag="active"][data-cursor-bubble="active"] .cursor-bubble .cursor-text-drag,
.custom-cursor[data-cursor-status-onlydrag="active"][data-cursor-bubble="active"] .cursor-bubble .cursor-text-drag {
   transform: translate(-50%, -50%) scale(1) rotate(0.001deg);
   opacity: 1;
   transition: all var(--animation-cursor) 0s;
}

/*  Cursor Drag Dots  */

.cursor-drag-dot {
   position: absolute;
   width: 2em;
   height: 4.5em;
   border-radius: 50%;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -70%) scale(0) rotate(0.001deg);
   transition: transform var(--animation-cursor);
   display: flex;
   align-items: center;
   justify-content: center;
}

.cursor-drag-dot svg {
   width: 100%;
   transform: rotate(-90deg);
}

.cursor-drag-dot svg path {
   fill: var(--color-primary);
}

.cursor-drag-dot.right {
   transform: translate(-50%, -70%) scale(0) rotate(0.001deg);
}

.cursor-drag-dot.right svg {
   transform: rotate(90deg);
}

.custom-cursor[data-cursor-status-move="active"][data-cursor-bubble="active"] .cursor-drag-dot.left,
.custom-cursor[data-cursor-status-drag="active"][data-cursor-bubble="active"] .cursor-drag-dot.left,
.custom-cursor[data-cursor-status-onlydrag="active"][data-cursor-bubble="active"] .cursor-drag-dot.left {
   transform: translate(-225%, -70%) scale(1) rotate(0.001deg);
}

.custom-cursor[data-cursor-status-move="active"][data-cursor-bubble="active"] .cursor-drag-dot.right,
.custom-cursor[data-cursor-status-drag="active"][data-cursor-bubble="active"] .cursor-drag-dot.right,
.custom-cursor[data-cursor-status-onlydrag="active"][data-cursor-bubble="active"] .cursor-drag-dot.right {
   transform: translate(125%, -70%) scale(1) rotate(0.001deg);
}

@media (hover: hover) {
   .lenis-smooth .custom-cursor[data-cursor-init="true"] {
      display: flex;
      opacity: 1;
      visibility: visible;
   }
}

/* ------------------------- Flickity Slider - Setup -------------------------------------------------- */

.flickity-slider-group {
   width: 100%;
   position: relative;
   --grid-gap: var(--gap);
   --columns: 3;
}

.flickity-slider-group .flickity-carousel {
   display: flex;
}

.flickity-slider-group .flickity-slide {
   width: calc((99.99% / var(--columns)) - (var(--grid-gap) * ((var(--columns) - 1) / var(--columns))));
   margin-right: var(--grid-gap);
   flex-shrink: 0;
   display: block;
}

/* Turn Flickity ON */
.flickity-slider-group .flickity-carousel::after {
   content: "flickity";
   display: none;
}

/* Remove Flex from for Flickity */
.flickity-slider-group .flickity-carousel {
   display: block;
}

.flickity-slider-group .flickity-viewport {
   overflow: visible;
   width: 100%;
}

/* Flickity Controls */

.flickity-slider-group .flickity-controls {
   position: absolute;
   top: 0;
   left: calc(var(--container-padding) * -1);
   width: 100vw;
   height: 100%;
   pointer-events: none;
   z-index: 1;
   display: none;
   align-items: center;
   justify-content: space-between;
}

.flickity-slider-group .flickity-controls .flickity-arrow {
   position: relative;
   width: 10vw;
   height: 100%;
   background: transparent;
   display: flex;
   justify-content: center;
   align-items: center;
   pointer-events: all;
}

.flickity-slider-group .flickity-controls .flickity-arrow[disabled] {
   opacity: 0;
   visibility: hidden;
}

/* ------------------------- Flickity Slider - Custom: Cards Four Col -------------------------------------------------- */

@media screen and (min-width: 1025px) {

   /* Turn Flickity OFF if 4 collection items */
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3, .count-4) .flickity-carousel::after {content:""; display: block;}
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3, .count-4) .flickity-carousel {display: flex;}
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3, .count-4) .flickity-controls {display: none;}
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"] .flickity-controls {
      display: flex;
   }

   .flickity-slider-group.four-col[data-flickity-slider-type="cards"] {
      --grid-gap: var(--gap);
      --columns: 4.2;
   }

   .flickity-slider-group.four-col:is(.count-1, .count-2, .count-3, .count-4)[data-flickity-slider-type="cards"] {
      --columns: 4;
   }
}

@media (min-width: 541px) and (max-width: 1024px) {

   /* Turn Flickity OFF if 3 collection items */
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3) .flickity-carousel::after {content:""; display: block;}
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3) .flickity-carousel {display: flex;}
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3) .flickity-controls {display: none;}
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"] .flickity-controls {
      display: flex;
   }

   .flickity-slider-group.four-col[data-flickity-slider-type="cards"] {
      --grid-gap: var(--gap);
      --columns: 3.15;
   }

   .flickity-slider-group.four-col:is(.count-1, .count-2, .count-3)[data-flickity-slider-type="cards"] {
      --columns: 3;
   }
}

@media screen and (max-width: 540px) {

   /* Turn Flickity OFF if 2 collection items */
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2) .flickity-carousel::after {content:""; display: block;}
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2) .flickity-carousel {display: flex;}
   .flickity-slider-group.four-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2) .flickity-controls {display: none;}

   .flickity-slider-group.four-col[data-flickity-slider-type="cards"] {
      --grid-gap: var(--container-padding);
      --columns: 2.1;
   }

   .flickity-slider-group.four-col:is(.count-2)[data-flickity-slider-type="cards"] {
      --columns: 2;
   }

   .flickity-slider-group.four-col:is(.count-1)[data-flickity-slider-type="cards"] {
      --columns: 1;
   }
}

/* ------------------------- Flickity Slider - Custom: Cards Three Col -------------------------------------------------- */

@media screen and (min-width: 841px) {

   /* Turn Flickity OFF if 3 collection items */
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3) .flickity-carousel::after {content:""; display: block;}
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3) .flickity-carousel {display: flex;}
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2, .count-3) .flickity-controls {display: none;}
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"] .flickity-controls {
      display: flex;
   }

   .flickity-slider-group.three-col[data-flickity-slider-type="cards"] {
      --grid-gap: var(--gap);
      --columns: 3.15;
   }

   .flickity-slider-group.three-col.twopointfive-col[data-flickity-slider-type="cards"] {
      --columns: 2.65;
   }

   .flickity-slider-group.three-col:is(.count-1, .count-2, .count-3)[data-flickity-slider-type="cards"] {
      --columns: 3;
   }
}

@media (min-width: 541px) and (max-width: 840px) {

   /* Turn Flickity OFF if 2 collection items */
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2) .flickity-carousel::after {content:""; display: block;}
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2) .flickity-carousel {display: flex;}
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1, .count-2) .flickity-controls {display: none;}

   .flickity-slider-group.three-col[data-flickity-slider-type="cards"] {
      --grid-gap: var(--gap);
      --columns: 2.1;
   }

   .flickity-slider-group.three-col:is(.count-1, .count-2)[data-flickity-slider-type="cards"] {
      --columns: 2;
   }
}

@media screen and (max-width: 540px) {

   /* Turn Flickity OFF if 1 collection items */
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-carousel::after {content:""; display: block;}
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-carousel {display: flex;}
   .flickity-slider-group.three-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-controls {display: none;}

   .flickity-slider-group.three-col[data-flickity-slider-type="cards"] {
      --grid-gap: var(--container-padding);
      --columns: 1.05;
   }

   .flickity-slider-group.three-col:is(.count-1)[data-flickity-slider-type="cards"] {
      --columns: 1;  
   } 
}

/* ------------------------- Flickity Slider - Custom: Cards Two Col (Vimeo) -------------------------------------------------- */

@media (min-width: 841px) {

   /* Turn Flickity OFF if 1 collection items */
   .flickity-slider-group.two-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-carousel::after {content:""; display: block;}
   .flickity-slider-group.two-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-carousel {display: flex;}
   .flickity-slider-group.two-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-controls {display: none;}

   .flickity-slider-group.two-col[data-flickity-slider-type="cards"] .flickity-controls {
      display: flex;
   }

   .flickity-slider-group.two-col[data-flickity-slider-type="cards"] {
      --grid-gap: var(--gap);
      --columns: 1.575;
   }

   .flickity-slider-group.two-col:is(.count-1)[data-flickity-slider-type="cards"] {
      --columns: 1;
   }
}

@media screen and (max-width: 840px) {

   /* Turn Flickity OFF if 1 collection items */
   .flickity-slider-group.two-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-carousel::after {content:""; display: block;}
   .flickity-slider-group.two-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-carousel {display: flex;}
   .flickity-slider-group.two-col[data-flickity-slider-type="cards"]:is(.count-1) .flickity-controls {display: none;}

   .flickity-slider-group.two-col[data-flickity-slider-type="cards"] .flickity-controls {
      display: flex;
   }

   .flickity-slider-group.two-col[data-flickity-slider-type="cards"] {
      --grid-gap: var(--container-padding);
      --columns: 1.05;
   }

   .flickity-slider-group:is(.count-1)[data-flickity-slider-type="cards"] {
      --columns: 1;  
   } 
}


/* ------------------------- Copyright Visual -------------------------------------------------- */

.copyright-visual {
   display: flex;
   position: relative;
   justify-content: flex-start;
   max-width: 14em;
}

.copyright-visual .visual {
   aspect-ratio: 1/1;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 1px solid var(--color-gray);
   border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.copyright-visual .visual svg {
   width: 100%;
}

.copyright-visual .visual svg path {
   fill: var(--color-primary);
}

.copyright-visual .text {
   display: flex;
   flex-direction: column;
   border: 1px solid var(--color-gray);
   border-left: 0px solid transparent;
   border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.copyright-visual .text .top {
   border-bottom: 1px solid var(--color-gray);
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.3em 0.35em;
}

.copyright-visual .text .top h4 {
   font-size: 1em;
   color: var(--color-gray);
   transform: translateY(0.05em);
   white-space: nowrap;
   text-align: center;
}

.copyright-visual .text .bottom {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.4em 0.3em;
}

.copyright-visual .text .bottom p {
   font-size: 0.425em;
   color: var(--color-gray);
   text-align: center;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   line-clamp: 2;
   min-height: 1.72em;
}

/* ------------------------- Logo Visual -------------------------------------------------- */

.logo-visual {
   display: flex;
   position: relative;
   justify-content: flex-start;
   width: 13em;
}

.logo-visual .visual {
   height: 4em;
   width: 4em;
   flex-shrink: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 1px solid var(--color-gray);
   border-radius: var(--border-radius) 0 0 var(--border-radius);
   position: relative;
}

.logo-visual .visual .overlay-logo .single-svg-logo {
   width: 70%;
   max-height: 50%;
}

.logo-visual .visual svg path {
   fill: var(--color-primary);
}

.logo-visual .text {
   display: flex;
   flex-direction: column;
   border: 1px solid var(--color-gray);
   border-left: 0px solid transparent;
   border-radius: 0 var(--border-radius) var(--border-radius) 0;
   width: 100%;
}

.logo-visual .text .top {
   border-bottom: 1px solid var(--color-gray);
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.3em 0.35em;
   width: 100%;
   flex-shrink: 0;
}

.logo-visual .text .top h4 {
   font-size: 1em;
   color: var(--color-gray);
   transform: translateY(0.05em);
   white-space: nowrap;
   text-align: center;
}

.logo-visual .text .bottom {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   position: relative;
   overflow: hidden;
}

.logo-visual .text .bottom .loop-text {
   --cursor-speed: 6;
   position: absolute;
   left: 0;
   display: flex;
   align-items: center;
   white-space: nowrap;
   transform-origin: left center;
}

.logo-visual .text .bottom .loop-text h3 {
   color: var(--color-gray);
}

.logo-visual .text .bottom .loop-text svg {
   width: 1.5em;
   margin-bottom: 0.25em;
}

.logo-visual .text .bottom .loop-text svg > * {
   fill: var(--color-gray);
}

.logo-visual .text .bottom .loop-text {
   animation: loopCursorNormal calc(var(--cursor-speed) / 0.5) linear;
   animation-iteration-count: infinite;
}

@keyframes loopCursorNormal { 
   0% { transform: translateX(0%); }
   100% {transform: translateX(-50%); } 
}

[data-theme-section="light"] .logo-visual .visual,
[data-theme-section="light"] .logo-visual .text,
[data-theme-section="light"] .logo-visual .text .top {
   border-color: var(--color-darkgray);
}

[data-theme-section="light"] .logo-visual .visual svg path,
[data-theme-section="light"] .logo-visual .text .bottom .loop-text svg > * {
   fill: var(--color-dark);
}

[data-theme-section="light"] .logo-visual .text .top h4,
[data-theme-section="light"] .logo-visual .text .bottom .loop-text h3 {
   color: var(--color-dark);
}

/* ------------------------- Gradient Overlay -------------------------------------------------- */

.overlay-gradient {
   width: 100vw;
   height: calc(100 * var(--vh));
   background-image: url("../img/grunge-gray.png");
   background-size: 10em 10em;
   background-position: right top;
   background-color: rgba(var(--color-gray-rgb), 0.15);
   mask-image: radial-gradient(at right top, rgba(var(--color-black-rgb), 0.75) 0%, rgba(var(--color-black-rgb), 0) 50%);
   position: absolute;
   top: 0;
   right: 0;
}

[data-theme-section="light"] .overlay-gradient {
   background-image: url("../img/grunge-darkgreen.png");
   background-color: rgba(var(--color-gray-rgb), 0.66);
}

[data-bg-section="gray"] .overlay-gradient {
   background-color: var(--color-white);
}

@supports not (mask-image: linear-gradient(to bottom, var(--color-dark) 0%, transparent 100%)) {
   .overlay-gradient {
      background-image: radial-gradient(at right top, rgba(var(--color-dark-rgb), 0.75) 0%,rgba(var(--color-dark-rgb), 1) 50%);
      background-size: cover;
   }

   [data-theme-section="light"] .overlay-gradient {
      background-image: radial-gradient(at right top, rgba(var(--color-light-rgb), 0.75) 0%,rgba(var(--color-light-rgb), 1) 40%);
      background-color: rgba(var(--color-gray-rgb), 0.3);
   }

   [data-bg-section="gray"] .overlay-gradient {
      background-image: radial-gradient(at right top, rgba(var(--color-white-rgb), 0) 0%,rgba(var(--color-white-rgb), 1) 40%);
      background-color: var(--color-white);
   }
}

/* ------------------------- Bubble -------------------------------------------------- */
 
.bubble {
   border-radius: 50em;
   border: 1px solid var(--color-border);
   padding: 0.3em 0.55em 0 0.55em;
}


/* ------------------------- Single Logo -------------------------------------------------- */

.overlay-logo {
   display: flex;
   align-items: center;
   justify-content: center;
}

.overlay-logo p {
   text-align: center;
}

.overlay-logo .single-svg-logo {
   width: 30%;
   max-height: 20%;
}

.single-svg-logo {
   display: flex;
   width: 100%;
   max-height: 100%;
}

.single-svg-logo svg {
   width: 100%;
   max-width: 100%;
   max-height: 100%;
}

.single-svg-logo p {
   position: absolute;
}

.single-svg-logo svg * {
   fill: var(--color-white);
}

/* ------------------------- Single Work Card -------------------------------------------------- */

.single-work-card {
   display: flex;
   flex-direction: column;
   width: 100%;
}

.single-work-card .card-row-image {
   display: flex;
   position: relative;
   padding-bottom: calc(var(--gap) - 0.1em);
}

.single-work-card .card-row-image {
   display: flex;
   position: relative;
}

.single-work-card .thumbnail, 
.single-work-slide .thumbnail {
   position: relative;
   width: 100%;
   overflow: hidden;
   border-radius: var(--border-radius-medium);
}

.single-work-card .thumbnail::before,
.single-work-slide .thumbnail::before {
   content: "";
   padding-top: 80%;
   display: block;
}

.single-work-card .thumbnail picture,
.single-work-slide .thumbnail picture {
   transition: transform var(--animation-primary);
   transform: scale(1.05) rotate(0.001deg);
}

.single-work-card .thumbnail video,
.single-work-slide .thumbnail video {
   transition: opacity 0.3s ease-in-out, transform var(--animation-primary);
   opacity: 0;
   z-index: 2;
   pointer-events: none;
   transform: scale(1.1) rotate(0.001deg);
}

.single-work-card .thumbnail .overlay-logo,
.single-work-slide .thumbnail .overlay-logo {
   z-index: 3;
}

.single-work-card h3 {
   color: var(--color-gray);
   transition: color var(--animation-primary);
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   line-clamp: 1;
   min-height: 1em;
   line-height: 1;
}

.single-work-card span.eyebrow.inactive {
   transition: opacity var(--animation-primary);
}

.single-work-slide .thumbnail[data-thumb-video-status="active"] picture {
   transform: scale(1.01) rotate(0.001deg);
}

.single-work-slide .thumbnail[data-thumb-video-status="active"] video {
   opacity: 1;
   transform: scale(1.01) rotate(0.001deg);
}

[data-theme-section="light"] .single-work-card h3 {
   color: var(--color-darkgray);
}

@media (hover: hover) { 


   .single-work-card[data-thumb-video-status="active"] picture {
      transform: scale(1.01) rotate(0.001deg);
   }

   .single-work-card[data-thumb-video-status="active"] video {
      opacity: 1;
      transform: scale(1.01) rotate(0.001deg);
   }

   .single-work-card:hover h3 {
      color: var(--color-white);
   }

   [data-theme-section="light"] .single-work-card:hover h3 {
      color: var(--color-dark);
   }
}

@media (hover: none) {
   .single-work-card video {
      display: none;
   }
} 

/* ------------------------- Single Team Card -------------------------------------------------- */

.single-team-card {
   display: flex;
   flex-direction: column;
   width: 100%;
}

.single-team-card .card-row-image {
   display: flex;
   position: relative;
   padding-bottom: calc(var(--gap) - 0.1em);
}

.single-team-card .card-row-image {
   display: flex;
   position: relative;
}

.single-team-card .thumbnail {
   position: relative;
   width: 100%;
   overflow: hidden;
   border-radius: var(--border-radius-medium);
}

.single-team-card .thumbnail .overlay-color {
   background-color: var(--color-gray);
   mix-blend-mode: multiply;
   transition: 0.4s ease;
   opacity: 0;
}

.single-team-card .thumbnail::before {
   content: "";
   padding-top: 133.333%;
   display: block;
}

.single-team-card .thumbnail picture {
   transform: scale(1.01) rotate(0.001deg);
   transition: 0.4s ease;
}

.single-team-card .thumbnail .overlay-quote {
   display: flex;
   align-items: flex-end;
   justify-content: center;
   padding: calc(var(--gap) * 2) var(--gap);
   background: linear-gradient(180deg, rgba(var(--color-black-rgb),0) 40%, rgba(var(--color-black-rgb), 0.4) 100%);
   transition: var(--animation-primary);
   transform: translateY(1em) rotate(0.001deg);
   opacity: 0;
}

.single-team-card .thumbnail .overlay-quote * {
   color: var(--color-white);
   text-align: center;
   font-size: calc(var(--title-size) * 0.45);
}

.single-team-card .thumbnail .overlay-quote *::before {
   content: "“";
}

.single-team-card .thumbnail .overlay-quote *::after {
   content: "”";
}

@media screen and (max-width: 540px) {

   .single-team-card .thumbnail .overlay-quote * {
      font-size: 6vw;
   }
}

.single-team-card .card-row-title {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.single-team-card h3 {
   color: var(--color-gray);
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   line-clamp: 1;
   min-height: 1em;
   line-height: 1;
}

.single-team-card .social-links {
   display: flex;
   align-items: flex-start;
   justify-content: flex-end;
   flex-shrink: 0;
   gap: 0.5em;
   transform: translateY(-0.1em);
}

[data-theme-section="light"] .single-team-card h3 {
   color: var(--color-darkgray);
}

[data-hover-children="true"] .thumbnail picture {
   filter: grayscale(1) blur(2px);
}

[data-hover-children="true"] .thumbnail .overlay-color {
   opacity: 0.5;
}

[data-hover-children="true"] li > *:hover .thumbnail picture {
   filter: grayscale(0) blur(0px);
   opacity: 1;
   transform: scale(1.03) rotate(0.001deg);
}

[data-hover-children="true"] li > *:hover .thumbnail .overlay-color {
   opacity: 0;
}

[data-hover-children="true"] li > *:hover .overlay-quote {
   transform: translateY(0em) rotate(0.001deg);
   opacity: 1;
}